var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MRow',{staticClass:"flex-grow",attrs:{"gutter":0}},[_c('MCol',{staticClass:"preview-holder",attrs:{"size":_vm.wrapperSize}},[(_vm.formFields.length === 0 && _vm.showNoData)?_c('div',[_vm._t("no-fields",function(){return [_c('FlotoNoData')]})],2):_c('FlotoContentLoader',{attrs:{"loading":_vm.loading}},[_c(_vm.withSubmit ? 'FlotoForm' : 'div',{key:_vm.renderCount,ref:"formRef",tag:"component",staticClass:"flex flex-wrap flex-col",class:{ 'read-only-form': _vm.disabled },attrs:{"id":"ticket-form"},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([(_vm.withSubmit)?{key:"submit",fn:function(submitSlotData){return [_c('div',{class:_vm.isRtl ? 'text-left' : 'text-right'},[(!_vm.disabled)?_vm._t("actions",function(){return [_vm._t("submit",function(){return [_c('MButton',{attrs:{"id":"create-btn","type":"submit"}},[_vm._t("submit-text",function(){return [_vm._v(_vm._s(_vm.$t('create')))]})],2)]},{"reset":submitSlotData.submit}),_vm._t("reset",function(){return [_c('MButton',{staticClass:"ml-4",attrs:{"id":"cancel-btn","variant":"default"},on:{"click":submitSlotData.reset}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])]},{"reset":submitSlotData.reset})]},{"submit":_vm.handleSubmit},submitSlotData):_vm._e()],2)]}}:null],null,true)},[_c('MRow',{staticClass:"flex-1",attrs:{"gutter":0}},[_vm._l((_vm.formFields),function(element){return [_c('Field',_vm._g({key:element.guid,attrs:{"id":element.guid,"data-guid":element.guid,"focus-event-brodcast":_vm.focusEventBrodcast,"disabled":_vm.disabled,"value":_vm.formValue[
                  element.isSystemField ? element.paramName : element.id
                ],"group-id":_vm.formValue.groupId,"field":element,"module-name":_vm.moduleName,"validate-archived-value":_vm.validateArchivedValue,"api-field-update-fn":_vm.apiFieldUpdateFn,"apply-form-rules":_vm.applyFormRules,"field-rules-state":_vm.fieldRulesState,"form-value":_vm.formValue,"dynamic-field-options":_vm.dynamicFieldOptionsMap[element.id]},on:{"templateSelected":_vm.handleTemplateSelected,"requesterSelected":_vm.handleRequesterSelected,"update-value":function($event){
                var _obj;
return _vm.handleUpdate(( _obj = {}, _obj[element.isSystemField ? element.paramName : element.id] = $event, _obj ))},"field-blur":function($event){
                var _obj;
return _vm.handleFieldBlur(( _obj = {}, _obj[element.isSystemField ? element.paramName : element.id] = '', _obj ))},"set-default-value":function ($event) { return _vm.handleSetFieldDefaultValue(element.paramName, $event); }}},_vm.listeners),[(element.type === 'section')?_c('div',{staticClass:"w-full flex flex-row flex-wrap h-full content-start relative"},[_vm._l((element.fields),function(sectionField){return [_c('Field',_vm._g({key:sectionField.guid,attrs:{"id":sectionField.guid,"data-guid":sectionField.guid,"focus-event-brodcast":_vm.focusEventBrodcast,"module-name":_vm.moduleName,"disabled":_vm.disabled,"value":_vm.formValue[
                        sectionField.isSystemField
                          ? sectionField.paramName
                          : sectionField.id
                      ],"field":sectionField,"validate-archived-value":_vm.validateArchivedValue,"api-field-update-fn":_vm.apiFieldUpdateFn,"apply-form-rules":_vm.applyFormRules,"field-rules-state":_vm.fieldRulesState,"dynamic-field-options":_vm.dynamicFieldOptionsMap[sectionField.id]},on:{"update-value":function($event){
                      var _obj;
return _vm.handleUpdate(( _obj = {}, _obj[sectionField.isSystemField
                          ? sectionField.paramName
                          : sectionField.id] = $event, _obj ))},"field-blur":function($event){
                      var _obj;
return _vm.handleFieldBlur(( _obj = {}, _obj[sectionField.isSystemField
                          ? sectionField.paramName
                          : sectionField.id] = '', _obj ))},"set-default-value":function ($event) { return _vm.handleSetFieldDefaultValue(
                          sectionField.paramName,
                          $event
                        ); }}},_vm.listeners))]})],2):_vm._e()])]}),(!_vm.withSubmit)?_vm._t("additional-fields",null,null,_vm.formValue):_vm._e()],2)],1),(_vm.withSubmit)?_vm._t("additional-fields",null,null,_vm.formValue):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }