<template>
  <FlotoFixedView :gutter="0" :no-height="scrollable">
    <FlotoPaginatedList
      v-slot="{
        loading,
        loadingMore,
        items,
        removeItem,
        appendItem,
        replaceItem,
        reset,
        prependItem,
        loadMoreItems,
        shouldRenderPagination,
        paginationMode,
        nextPage,
        navigateToPage,
        previousPage,
        pageInfo,
        error,
        replaceAllItems,
        toggleSelectItem,
        toggleSelectAll,
        selectedItemIds,
        sortedColumn,
        applySort,
        changePageSize,
        currentCursorPosition,
        // toggleExpand,
      }"
      ref="list"
      :allow-keyboard-navigation="allowKeyboardNavigation"
      :immediate="immediate"
      :default-sort="defaultSort"
      :fetch-fn="fetchFn"
      :page-size="currentPageSize"
      :page="page"
      :paging="paging"
      :max-allowed-selection="maxAllowedSelection"
      :default-selected-item-ids="defaultSelectedItemIds"
      @itemsReceived="handleNewItemsReceived"
      @selection-change="$emit('selection-change', $event)"
      @select-item="$emit('select-item', $event)"
    >
      <div class="flex flex-col min-w-0 w-full min-h-0" style="flex: 1">
        <FlotoCrud
          ref="crud"
          v-slot="{
            activateItem,
            editingItem,
            create,
            edit,
            submitForm,
            remove,
            hideForm,
            processing,
            activeItem,
            resetActiveItem,
            setProcessing,
            resetForm,
          }"
          :update-fn="updateFn"
          :create-fn="createFn"
          :delete-fn="deleteFn"
          :default-item="defaultItem"
          @form-hide="$emit('form-hide')"
        >
          <div class="flex flex-col h-100">
            <div :class="{ 'px-4': scrollable }">
              <slot
                name="add-controls"
                :create="create"
                :loading="loading"
                :append-item="appendItem"
                :refresh-list="reset"
                :activate-item="activateItem"
                :active-item="activeItem"
                :sorted-column="sortedColumn"
                :apply-sort="applySort"
                :items="items"
                :reset-active-item="resetActiveItem"
              ></slot>
            </div>
            <!-- allow to override form entirely -->
            <slot
              name="form"
              :editing-item="editingItem"
              :submit="
                getSubmitEditingItemFormHandler(
                  submitForm,
                  editingItem,
                  hideForm,
                  replaceItem,
                  prependItem,
                  appendItem
                )
              "
              :cancel="hideForm"
              :default-item="defaultItem"
            >
              <!-- default form as drawer -->
              <FlotoDrawerForm
                ref="drawerFormRef"
                :width="formWidth"
                :open="editingItem !== null"
                @show="setProcessing(false)"
                @submit="
                  handleFormSubmit(
                    submitForm,
                    editingItem,
                    hideForm,
                    editingItem.id
                      ? replaceItem
                      : prependNewItem
                      ? prependItem
                      : appendItem
                  )
                "
                @cancel="hideForm"
              >
                <template v-if="editingItem" v-slot:header>
                  <slot name="form-header" :item="editingItem"></slot>
                </template>
                <slot
                  v-if="editingItem"
                  name="form-items"
                  :item="editingItem"
                  :reset-form="
                    getResetFormHandler(
                      resetForm,
                      items.find((i) => i.id === editingItem.id)
                    )
                  "
                ></slot>
                <template
                  v-if="editingItem"
                  v-slot:actions="{ hide, submit, ...formSlotData }"
                >
                  <slot
                    name="form-actions"
                    :processing="processing"
                    :item="editingItem"
                    :cancel="hide"
                    :submit="submit"
                    v-bind="formSlotData"
                  ></slot>
                </template>
              </FlotoDrawerForm>
            </slot>
            <slot name="before-list"></slot>

            <!-- when some items are selected we allow bulk actions -->
            <slot
              name="bulk-action-section"
              :refresh-list="reset"
              :toggle-select-all="toggleSelectAll"
              :selected-item-ids="selectedItemIds"
              :items="items"
            >
              <div
                v-if="selectedItemIds.length && $scopedSlots['bulk-actions']"
                :class="{ 'px-4': scrollable }"
              >
                <div class="p-2 flex items-center bulk-action-header">
                  <MTooltip>
                    <template v-slot:trigger>
                      <a class="mr-2" @click="toggleSelectAll(false)">
                        <MIcon name="minus-square" size="lg" />
                      </a>
                    </template>
                    {{ $t('unselect') }} {{ $t('all') }}
                  </MTooltip>
                  <slot
                    name="bulk-actions"
                    :refresh-list="reset"
                    :toggle-select-all="toggleSelectAll"
                    :selected-item-ids="selectedItemIds"
                    :items="items"
                  />
                </div>
              </div>
            </slot>
            <!-- Bulk action section ends -->
            <div class="flex flex-1 flex-col min-h-0">
              <FlotoContentLoader :loading="loading">
                <template v-slot:waiting>
                  <!-- show placeholder loading if currently its loading data -->
                  <slot v-if="loading" name="loading">
                    <MRow class="w-full" :class="{ 'px-4': scrollable }">
                      <MCol :xxl="12">
                        <MPlaceholder
                          width="100%"
                          :height="200"
                          primary-color="var(--loader-primary-color)"
                          secondary-color="var(--loader-secondary-color)"
                        >
                        </MPlaceholder>
                      </MCol>
                    </MRow>
                  </slot>
                </template>
                <MRow
                  class="flex-1 flex-no-wrap overflow-hidden min-h-0"
                  :gutter="0"
                >
                  <MCol class="min-w-0">
                    <component
                      :is="scrollable ? 'FlotoScrollView' : 'div'"
                      :show-duration="$attrs['scroll-show-duration']"
                    >
                      <div
                        class="flex flex-1 flex-col"
                        :class="{ 'px-4': scrollable }"
                      >
                        <!-- allow to override entire items display box -->
                        <slot
                          v-if="!loading && !error"
                          name="list-items"
                          v-bind="{
                            submit: getSubmitEditingItemFormHandler(
                              submitForm,
                              editingItem,
                              hideForm,
                              replaceItem,
                              prependItem,
                              appendItem
                            ),
                            items,
                            edit,
                            create,
                            activeItem,
                            activateItem,
                            sortedColumn,
                            applySort,
                            update: (payload, item) => {
                              return submitForm(payload, item).then(
                                (response) => {
                                  replaceItem(response, item)
                                  if (response.id === (activeItem || {}).id) {
                                    activateItem(response, item)
                                  }
                                }
                              )
                            },
                            appendItem,
                            prependItem,
                            replaceItem,
                            removeItem,
                            remove: (x) => remove(x).then(() => removeItem(x)),
                            reset,
                            replaceAllItems,
                            resetActiveItem,
                            toggleSelectItem,
                            toggleSelectAll,
                            selectedItemIds,
                            navigateToPage,
                            changePageSize,
                            pageInfo,
                            currentCursorPosition,
                          }"
                        >
                          <!-- if as table then provide table structure -->
                          <ResizableTable
                            v-if="asTable"
                            :use-scroll="!scrollable"
                            :disabled="
                              (selectedItemIds.length &&
                                $scopedSlots['bulk-actions']) ||
                              hideTableHeaders
                            "
                            sticky-headers
                            v-bind="items.length === 0 ? { minHeight: 0 } : {}"
                          >
                            <table
                              key="tabular-list"
                              class="item-list-table w-full"
                            >
                              <thead
                                v-if="
                                  !(
                                    selectedItemIds.length &&
                                    $scopedSlots['bulk-actions']
                                  ) && hideTableHeaders !== true
                                "
                              >
                                <tr>
                                  <!-- <td
                                    v-if="hasDetailRow"
                                    class="sticky checkbox"
                                  >
                                    &nbsp;
                                  </td> -->
                                  <td v-if="selectable" class="checkbox sticky">
                                    <MCheckbox
                                      v-if="
                                        maxAllowedSelection === 0 &&
                                        items.length > 0
                                      "
                                      :checked="
                                        selectedItemIds.length ===
                                          items.length && items.length > 0
                                      "
                                      @change="toggleSelectAll"
                                    />
                                  </td>
                                  <td
                                    v-for="column in columns"
                                    :key="column.key"
                                    class="text-ellipsis sticky"
                                    :class="{
                                      [column.class || '']: true,
                                      'non-resizable':
                                        column.resizable === false,
                                    }"
                                    :style="columnStyle(column)"
                                    :data-initial-width="column.width"
                                    :align="
                                      column.align ? column.align : undefined
                                    "
                                  >
                                    <div class="flex items-center">
                                      <span
                                        class="min-w-0 flex-1 text-ellipsis"
                                      >
                                        {{ column.name }}
                                      </span>
                                      <MIcon
                                        v-if="column.sortable"
                                        class="cursor-pointer"
                                        :name="
                                          sortedColumn
                                            ? sortedColumn.replace('-', '') ===
                                              column.key
                                              ? /^-/.test(sortedColumn)
                                                ? 'sort-up'
                                                : 'sort-down'
                                              : 'sort'
                                            : 'sort'
                                        "
                                        @click="applySort(column.key)"
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </thead>
                              <SortableList
                                v-if="items.length"
                                :value="items"
                                tag="tbody"
                                :disabled="!sortable"
                                handle=".drag-handle"
                                @drag-start="$emit('drag-start')"
                                @drag-end="$emit('drag-end')"
                                @update="
                                  handleItemsOrderChange(
                                    $event,
                                    items,
                                    replaceAllItems
                                  )
                                "
                              >
                                <template v-for="(item, index) in items">
                                  <tr
                                    :id="item.name"
                                    :key="item.id"
                                    :class="{
                                      'active-cursor':
                                        allowKeyboardNavigation &&
                                        index === currentCursorPosition,
                                    }"
                                  >
                                    <!-- <td
                                      v-if="hasDetailRow"
                                      class="sticky checkbox cursor-pointer"
                                      @click="toggleExpand(item)"
                                    >
                                      <MIcon
                                        v-if="item.canExpand"
                                        :name="
                                          `chevron-${
                                            item.expanded ? 'down' : 'right'
                                          }`
                                        "
                                      />
                                    </td> -->
                                    <td v-if="selectable" class="checkbox">
                                      <MCheckbox
                                        :checked="
                                          selectedItemIds.indexOf(item.id) >=
                                            0 ||
                                          defaultSelected.indexOf(item.id) >= 0
                                        "
                                        :disabled="
                                          (selectedDisabled &&
                                            defaultSelected.indexOf(item.id) >=
                                              0) ||
                                          item.disableSelection
                                        "
                                        @change="toggleSelectItem(item)"
                                      />
                                    </td>
                                    <slot
                                      v-for="column in columns"
                                      :activate-item="activateItem"
                                      :index="index"
                                      :edit="() => edit(item)"
                                      :create="(payload) => create(payload)"
                                      :refresh-list="reset"
                                      :active-item="activeItem"
                                      :is-first-item="index === 0"
                                      :is-last-item="index === items.length - 1"
                                      :update="
                                        (payload) =>
                                          submitForm(payload).then((response) =>
                                            replaceItem(response)
                                          )
                                      "
                                      :remove="
                                        () =>
                                          remove(item).then(() =>
                                            removeItem(item)
                                          )
                                      "
                                      :item="item"
                                      :items="items"
                                      :reset-active-item="resetActiveItem"
                                      :current-cursor-position="
                                        currentCursorPosition
                                      "
                                      :name="column.key"
                                      :toggle-select-all="toggleSelectAll"
                                      :processing="processing"
                                    >
                                      <td
                                        :key="column.key"
                                        class="text-ellipsis"
                                      >
                                        {{ item[column.key] }}
                                      </td>
                                    </slot>
                                  </tr>
                                  <!-- <tr
                                    v-if="hasDetailRow && item.expanded"
                                    :key="`${item.id}-detail`"
                                  >
                                    <td
                                      :colspan="
                                        columns.length +
                                          1 +
                                          (selectable ? 1 : 0)
                                      "
                                    >
                                      <slot
                                        name="detailRow"
                                        :item="item"
                                        :toggleExpand="() => toggleExpand(item)"
                                      />
                                    </td>
                                  </tr> -->
                                </template>
                              </SortableList>
                            </table>
                          </ResizableTable>

                          <!-- if not as table than provide direct slots -->
                          <div
                            v-if="items.length && !asTable && !loading"
                            class="flex-1"
                          >
                            <slot
                              v-for="(item, index) in items"
                              :index="index"
                              :is-first-item="index === 0"
                              :is-last-item="index === items.length - 1"
                              :activate-item="activateItem"
                              :edit="() => edit(item)"
                              :create="(payload) => create(payload)"
                              :active-item="activeItem"
                              :reset-active-item="resetActiveItem"
                              :current-cursor-position="currentCursorPosition"
                              :replace-all-items="replaceAllItems"
                              :update="
                                (payload) =>
                                  submitForm(payload).then((response) => {
                                    replaceItem(response)
                                    if (response.id === (activeItem || {}).id) {
                                      activateItem(response)
                                    }
                                  })
                              "
                              :remove="
                                () => remove(item).then(() => removeItem(item))
                              "
                              name="item"
                              :item="item"
                              :sorted-column="sortedColumn"
                              :apply-sort="applySort"
                              :toggle-select-item="() => toggleSelectItem(item)"
                              :is-selected="
                                selectedItemIds.indexOf(item.id) >= 0
                              "
                            >
                            </slot>
                          </div>
                        </slot>

                        <!-- show not data found if no data is there -->
                        <slot
                          v-if="
                            !items.length && !error && showNoData && !loading
                          "
                          name="no-data"
                        >
                          <FlotoNoData :size="messageSize" />
                        </slot>

                        <!-- show error if api returns no data -->
                        <slot
                          v-if="error"
                          name="error"
                          :refresh="reset"
                          :error="error"
                        >
                          <FlotoLoadError
                            :size="messageSize"
                            :authorization-error="
                              error.response && error.response.status === 403
                            "
                          >
                            <MButton @click="reset">
                              {{ $t('reload') }}
                            </MButton>
                          </FlotoLoadError>
                        </slot>
                      </div>
                    </component>
                  </MCol>
                </MRow>
                <slot
                  v-if="shouldRenderPagination && !loading"
                  name="pagination"
                  :pagination-mode="paginationMode"
                  :load-more-items="loadMoreItems"
                  :next-page="nextPage"
                  :previous-page="previousPage"
                  :navigate-to-page="navigateToPage"
                  :change-page-size="changePageSize"
                  :page-info="pageInfo"
                >
                  <MRow
                    class="my-2"
                    :class="{ 'px-4': scrollable }"
                    :gutter="0"
                  >
                    <MCol
                      :class="{
                        'text-center': paginationMode === 'loadmore',
                        'text-right': paginationMode === 'paging',
                      }"
                    >
                      <MButton
                        v-if="paginationMode === 'loadmore' && !loadingMore"
                        :loading="loading"
                        @click="loadMoreItems"
                      >
                        {{ $t('load_more') }}
                      </MButton>
                      <MPagination
                        v-if="paginationMode !== 'loadmore'"
                        v-model="pageInfo.current"
                        :hide-on-single-page="false"
                        size="small"
                        :page-size="pageInfo.pageSize"
                        show-size-changer
                        :total="pageInfo.total"
                        :show-total="
                          (total, range) =>
                            $t('pagination_info', {
                              from: range[0],
                              to: range[1],
                              total,
                              item: resourceName || $tc('record', 2),
                            })
                        "
                        @change="navigateToPage"
                        @update:page-size="changePageSize"
                      >
                        <template v-slot="slotData">
                          <span>{{ slotData.value }} / {{ $tc('page') }}</span>
                        </template>
                      </MPagination>
                    </MCol>
                  </MRow>
                </slot>
                <slot
                  name="after-list"
                  :selected-item-ids="selectedItemIds"
                  :refresh-list="reset"
                ></slot>
                <!-- show placeholder separately for load more type of lists -->
                <MRow
                  v-if="loadingMore && paginationMode === 'loadmore'"
                  class="w-full mt-4"
                  :class="{ 'px-4': scrollable }"
                  :gutter="0"
                >
                  <MCol :xxl="12">
                    <slot name="loading">
                      <MPlaceholder
                        width="100%"
                        :height="200"
                        primary-color="var(--loader-primary-color)"
                        secondary-color="var(--loader-secondary-color)"
                      />
                    </slot>
                  </MCol>
                </MRow>
              </FlotoContentLoader>
            </div>

            <slot
              v-bind="{
                activeItem,
                activateItem,
                resetActiveItem,
                editingItem,
                appendItem,
                prependItem,
                replaceItem,
                removeItem,
                toggleSelectItem: () => toggleSelectItem(item),
                selectedItemIds,
                remove: (item) => remove(item).then(() => removeItem(item)),
                reset,
                items,
                update: (payload) =>
                  submitForm(payload).then((response) => {
                    replaceItem(response)
                    if (response.id === (activeItem || {}).id) {
                      activateItem(response)
                    }
                  }),
                loading,
              }"
            />
          </div>
        </FlotoCrud>
      </div>
    </FlotoPaginatedList>
  </FlotoFixedView>
</template>

<script>
import Pick from 'lodash/pick'
import CloneDeep from 'lodash/cloneDeep'
import { PreferenceComputed } from '@state/modules/preference'
import ResizableTable from '@components/table/resizable-table'

export default {
  name: 'FlotoCrudContainer',
  components: {
    SortableList: () => import('@components/sortable/sortable-list'),
    ResizableTable,
  },
  props: {
    moduleName: { type: String, default: undefined },
    allowKeyboardNavigation: { type: Boolean, default: false },
    allAvailableColumns: { type: Array, default: undefined },
    columns: { type: Array, required: false, default: undefined },
    hideTableHeaders: { type: Boolean, default: false },
    // eslint-disable-next-line
    paging: { type: Boolean, default: true },
    asTable: { type: Boolean, default: false },
    // eslint-disable-next-line
    immediate: { type: Boolean, default: true },
    selectable: { type: Boolean, default: false },
    descendingSorting: { type: Boolean, default: false },
    // eslint-disable-next-line
    scrollable: { type: Boolean, default: true },
    perPage: { type: Number, default: undefined },
    defaultSort: { type: String, default: undefined },
    fetchFn: { type: Function, required: true },
    updateFn: { type: Function, required: false, default: undefined },
    createFn: { type: Function, required: false, default: undefined },
    deleteFn: { type: Function, required: false, default: undefined },
    bulkUpdateFn: { type: Function, required: false, default: undefined },
    // eslint-disable-next-line
    showNoData: { type: Boolean, default: true },
    defaultItem: {
      type: Object,
      default: undefined,
    },
    prependNewItem: {
      type: Boolean,
      default: false,
    },
    resourceName: { type: String, default: undefined },
    sortable: { type: Boolean, default: false },
    maxAllowedSelection: { type: Number, default: 0 },
    formWidth: { type: [Number, String], default: undefined },
    messageSize: { type: String, default: undefined },
    defaultSelected: {
      type: Array,
      default() {
        return []
      },
    },
    defaultSelectedItemIds: {
      type: Array,
      default() {
        return []
      },
    },
    selectedDisabled: { type: Boolean, default: false },
    page: { type: Number, default: 1 },
  },
  computed: {
    ...PreferenceComputed,
    currentPageSize() {
      return this.perPage || this.pageSize(this.moduleName)
    },
    // hasDetailRow() {
    //   return this.$scopedSlots.detailRow
    // },
  },
  methods: {
    getSubmitEditingItemFormHandler(
      submitFn,
      editingItem,
      hideForm,
      replaceItem,
      prependItem,
      appendItem
    ) {
      return (payload) =>
        this.handleFormSubmit(
          submitFn,
          payload || editingItem,
          hideForm,
          editingItem && editingItem.id
            ? replaceItem
            : this.prependNewItem
            ? prependItem
            : appendItem
        )
    },
    getResetFormHandler(resetFormFn, editingItem) {
      return (payload) => resetFormFn(payload || editingItem)
    },
    columnStyle(column) {
      return Pick(column, ['width', 'minWidth'])
    },
    handleItemsOrderChange(event, items, replaceAllFn) {
      const untouchedItems = CloneDeep(items)
      if (!this.bulkUpdateFn) {
        throw new Error('Bulk Update function is not defined')
      }
      let sortedItems = CloneDeep(event.items)
      if (this.descendingSorting) {
        sortedItems = sortedItems.reverse()
      }
      const updatedItems = sortedItems.map((i, index) => ({
        ...i,
        order: index + 1,
      }))
      replaceAllFn(event.items)
      const idsToUpdate = event.updatedItems.map((i) => i.id)
      const itemsToUpdate = idsToUpdate.map((id) =>
        updatedItems.find((i) => i.id === id)
      )
      this.bulkUpdateFn(itemsToUpdate).catch(() => {
        replaceAllFn(untouchedItems)
      })
    },
    handleNewItemsReceived(items) {
      this.$emit('items-received', items)
      if (this.$refs.crud) {
        const i = this.$refs.crud.getCurrentActiveItem()
        if (i && i.id) {
          const foundItem = items.find(({ id }) => id === i.id)
          if (!foundItem) {
            this.$refs.crud.resetActiveItem()
          }
        }
      }
    },
    getSubmitHandler(...args) {
      return () => this.handleFormSubmit(...args)
    },
    handleFormSubmit(submitFn, updatedItem, hideForm, resultActionFn) {
      return submitFn(updatedItem).then((response) => {
        hideForm()
        if (response) {
          this.$emit('submitted', response)
          resultActionFn(response)
        }
      })
    },
    refresh() {
      this.$refs.list.refresh()
    },
    refreshCurrentPage() {
      this.$refs.list.refreshCurrentPage()
    },
    startLoading() {
      this.$refs.list.startLoading()
    },
    showCreateForm(defaultValue) {
      this.$refs.crud.showForm(defaultValue)
    },
    editItem(item) {
      this.$refs.crud.editItem(item)
    },
    setActiveItem(item) {
      this.$refs.crud.markItemAsActive(item)
    },
    deleteItem(item) {
      this.$refs.list.remove(item)
    },
    addItem(item, prepend = false) {
      this.$refs.list[prepend ? 'prepend' : 'append'](item)
    },
  },
}
</script>
