<template>
  <div>
    <slot>
      <FlotoDropdownPicker :disabled="disabled" :options="[]">
        <template v-slot:trigger>
          <a
            v-if="clickable"
            target="_blank"
            :href="
              valueItem
                ? $router.resolve(
                    $modules.getModuleRoute('asset', 'view', {
                      params: {
                        id: valueItem.id,
                        assetType: moduleName,
                      },
                    })
                  ).href
                : undefined
            "
          >
            {{ valueItem ? `${valueItem.name} ${valueItem.displayName}` : '' }}
          </a>
          <DropdownTrigger
            v-else
            :disabled="disabled"
            :toggle="handleTriggerClick"
            :title="
              valueItem ? `${valueItem.name} ${valueItem.displayName}` : ''
            "
            :selected-item="
              valueItem
                ? {
                    text: `${valueItem.name} ${valueItem.displayName}`,
                    key: valueItem.id,
                  }
                : {}
            "
            :allow-clear="true"
            :as-input="asInput"
            :is-open="false"
            :placeholder="placeholder"
            @reset="handleResert"
          />
        </template>
      </FlotoDropdownPicker>
    </slot>

    <FlotoDrawer :open="showDrawer" width="55%" @hide="hideDrawer">
      <template v-slot:title>
        {{ placeholder }}
      </template>
      <AssetSelectionList
        hide-search-selector
        selectable
        :fetch-fn="fetchAssetsFn"
        :module-name="moduleName"
        searchable
        :exclude-items-by-id="excludeItemsById"
        :max-allowed-selection="1"
        @selection-change="setSelectedItems"
      />
      <template v-slot:actions="{ hide }">
        <MButton
          id="add-btn"
          :disabled="selectedItems.length === 0"
          outline
          class="mr-2"
          :loading="processing"
          @click="handleSubmitSelection"
        >
          {{ $t('add') }}
        </MButton>
        <MButton id="cancel-btn" variant="default" @click="hide">
          {{ $t('cancel') }}
        </MButton>
      </template>
    </FlotoDrawer>
  </div>
</template>

<script>
import {
  getApi,
  searchSoftwareAssetsWithoutOSApi,
} from '@modules/asset/asset-api'
import DropdownTrigger from '@components/dropdown-trigger'
import AssetSelectionList from '@components/item-selection-list/asset-list'
export default {
  name: 'AssetPickerDrawer',
  components: { AssetSelectionList, DropdownTrigger },
  model: {
    event: 'change',
  },
  props: {
    value: { type: Number, default: undefined },
    disabled: { type: Boolean, default: false },
    clickable: { type: Boolean, default: false },
    fetchFn: { type: Function, default: undefined },
    moduleName: { type: String, default: undefined },
    // eslint-disable-next-line
    asInput: { type: Boolean, default: true },
    placeholder: {
      type: String,
      default() {
        return `${this.$tc('select')} ${this.$tc(this.moduleName)}`
      },
    },
  },
  data() {
    return {
      showDrawer: false,
      processing: false,
      selectedItems: [],
      valueItem: null,
    }
  },
  computed: {
    excludeItemsById() {
      if (this.value) {
        return [this.value]
      }
      return []
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          getApi(this.moduleName, newValue).then((data) => {
            this.valueItem = data
          })
        }
      },
    },
  },
  methods: {
    fetchAssetsFn(moduleName, criterias, limit, offset) {
      if (this.fetchFn) {
        return this.fetchFn(moduleName, criterias, limit, offset)
      }
      return searchSoftwareAssetsWithoutOSApi(criterias, limit, offset)
    },
    handleSubmitSelection() {
      if (this.processing) {
        return
      }
      this.processing = true
      this.$emit('change', this.selectedItems.map((i) => i.id)[0])
      this.processing = false
      this.hideDrawer()
    },
    handleResert() {
      this.$emit('change', 0)
      this.valueItem = null
    },
    hideDrawer() {
      this.selectedItems = []
      this.showDrawer = false
    },
    setSelectedItems(items) {
      this.selectedItems = items
    },
    handleTriggerClick() {
      if (this.disabled) {
        return
      }
      setTimeout(() => {
        this.showDrawer = true
      }, 250)
    },
  },
}
</script>
