<script>
import IsEqual from 'lodash/isEqual'
import { StatusComputed } from '@state/modules/status'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'FlotoStatusPicker',
  extends: _baseDropdownPicker,
  props: {
    moduleName: { type: String, default: undefined, required: true },
    mandatory: { type: Boolean, default: false },
    excludedStatus: {
      type: Array,
      default() {
        return []
      },
    },
    allowedStatus: {
      type: Array,
      default() {
        return []
      },
    },
    stage: { type: String, default: undefined },
  },
  computed: {
    ...StatusComputed,
    transformedModuleName() {
      if (this.moduleName === this.$constants.SERVICE_CATALOG) {
        return this.$constants.REQUEST
      }
      return this.moduleName
    },
    currentOptions() {
      let currentList = this[`${this.transformedModuleName}Status`] || []
      if (this.moduleName === this.$constants.TASK) {
        currentList = currentList.map((s) => {
          return {
            ...s,
            disabled: (s.systemName || '').toLowerCase() === 'cancelled',
          }
        })
      }
      if (this.excludedStatus.length) {
        currentList = currentList.filter(
          (o) =>
            this.excludedStatus.indexOf((o.systemName || '').toLowerCase()) ===
            -1
        )
      }
      if (this.allowedStatus.length) {
        currentList = currentList.filter(
          (o) =>
            this.allowedStatus.indexOf((o.systemName || '').toLowerCase()) !==
            -1
        )
      }
      if (
        this.stage &&
        (this.moduleName === this.$constants.CHANGE ||
          this.moduleName === this.$constants.RELEASE)
      ) {
        currentList = currentList
          .filter((o) => o.stage === this.stage)
          .map((s) => ({ ...s, text: s.name }))
      }
      return currentList
    },
    options() {
      return this.currentOptions
    },
  },
  watch: {
    options: {
      handler(newValue, prevValue) {
        if (!IsEqual(newValue, prevValue)) {
          this.setDefaultValue()
        }
      },
    },
  },
  created() {
    this.setDefaultValue()
  },
  methods: {
    setDefaultValue() {
      if (!this.multiple && !this.value && this.mandatory) {
        let defaultValue = this.options.find((v) => v.default)
        if (!defaultValue) {
          defaultValue = this.options[0]
        }
        if (defaultValue) {
          this.$emit('change', defaultValue.key)
          this.$emit('set-default-value', defaultValue.key)
        }
      }
    },
  },
}
</script>
