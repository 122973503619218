<template>
  <CustomIcon :name="iconName" v-bind="$attrs" />
</template>

<script>
import { findValueObject } from '@data/recursive'
import { AssetTypeComputed } from '@state/modules/asset-type'
import CustomIcon from './custom-icon'
export default {
  name: 'AssetTypeIcon',
  components: { CustomIcon },
  props: { assetTypeId: { type: [Number, String], required: true } },
  computed: {
    ...AssetTypeComputed,
    item() {
      return findValueObject(this.assetTypes, this.assetTypeId) || {}
    },
    iconName() {
      const item = this.item
      if (item.systemName) {
        return `${(item.systemName || '')
          .split(' ')
          .join('')
          .toLowerCase() || ''}Icon`
      }
      if (item.iconSrc) {
        return item.iconSrc
      }
      return 'otherIcon'
    },
  },
}
</script>
