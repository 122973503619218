<template>
  <TreePicker
    ref="treePickerRef"
    :options="availableOptions"
    :value="value"
    searchable
    :placeholder="placeholder"
    :unassigned-value="unassignedValue"
    :multiple="multiple"
    :full-path="fullPath"
    v-bind="$attrs"
    :as-input="asInput"
    :only-leaf-node-selectable="isOnlyLeafNodeSelectable"
    @blur="$emit('blur')"
    @change="$emit('change', $event)"
    @selected="$emit('selected', $event)"
  >
    <template v-slot:trigger="slotData">
      <slot name="trigger" v-bind="slotData">
        <MTooltip v-if="suggestedItem && !value && !asInput">
          <template v-slot:trigger>
            <MTag
              class="flex items-center"
              :variant="undefined"
              :closable="false"
              :style="{
                ...currentStyle,
              }"
              @click="slotData.toggle"
            >
              <MIcon name="lightbulb-exclamation" />
              <span class="blink_me mx-2">
                {{ suggestedItem.name }}
              </span>
            </MTag>
          </template>
          {{ $tc('suggested') }} {{ $tc('value') }}: {{ suggestedItem.name }}
        </MTooltip>
      </slot>
    </template>
    <template v-if="suggestedItem && !value" v-slot:before-menu>
      <div class="suggested-item-container" @click="handleSuggestedItemSelect">
        <MTooltip placement="topLeft">
          <template v-slot:trigger>
            <div class="text-ellipsis">
              <div class="font-bold">
                {{ $tc('suggested') }} {{ $tc('value') }}
              </div>
              {{ suggestedItem.name }}
            </div>
          </template>
          {{ suggestedItem.name }}
        </MTooltip>
      </div>
    </template>
  </TreePicker>
</template>

<script>
import SortBy from 'lodash/sortBy'
import { PreferenceComputed } from '@state/modules/preference'
import { getTagStyleFromColor } from '@utils/color'
import { flattenRecursive, buildHierarchy } from '@data/recursive'
import { CategoryComputed } from '@state/modules/category'
// import _baseTreePicker from '../_base-tree-select.vue'
import { searchCategoriesApi } from '../../modules/category/category-api'
import { buildRelationalQualificationStructure } from '../../data/qualification'
import TreePicker from '@components/tree-picker/tree-picker'

export default {
  name: 'FlotoCategoryPicker',
  // extends: _baseTreePicker,
  components: { TreePicker },
  model: { event: 'change' },
  props: {
    moduleName: { type: String, default: undefined, required: true },
    validateArchivedValue: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    placeholder: {
      type: String,
      default() {
        return this.$tc('select')
      },
    },
    unassignedValue: { type: [String, Number], default: 0 },
    value: { type: [Number, Array, String], default: undefined },
    suggestedValue: { type: [Number, Array, String], default: undefined },
    // eslint-disable-next-line
    asInput: { type: Boolean, default: true },
    // eslint-disable-next-line
    onlyLeafNodeSelectable: { type: Boolean, default: true },
    fullPath: { type: Boolean, default: false },
  },
  data() {
    return {
      archivedOptions: [],
    }
  },
  computed: {
    ...CategoryComputed,
    ...PreferenceComputed,
    transformedModuleName() {
      if (this.moduleName === this.$constants.SERVICE_CATALOG) {
        return this.$constants.REQUEST
      }
      return this.moduleName
    },
    isOnlyLeafNodeSelectable() {
      if (this.onlyLeafNodeSelectable) {
        return (
          this[`${this.transformedModuleName}OnlyLeafNodeSelectable`] || false
        )
      }
      return false
    },
    availableOptions() {
      if (this.$attrs['additional-options']) {
        return (this.$attrs['additional-options'] || []).concat(this.options)
      }
      return this.options
    },
    options() {
      // @TODO when value is given and total is more than 1000 then we need to use search api to get items
      const options = this[`${this.transformedModuleName}Categories`]
      const flattenedOptions = flattenRecursive(options)
      const flatOptions = SortBy(
        flattenedOptions.filter((c) => !c.archived),
        'order'
      )
      const value = Array.isArray(this.value) ? this.value : [this.value]
      const selectedOptions = value.length
        ? flattenedOptions
            .filter((o) => value.indexOf(o.id) >= 0)
            .map((o) => ({ ...o, disabled: true }))
        : []
      let hierarchy = buildHierarchy(flatOptions)
      if (selectedOptions.length) {
        const availableIds = flatOptions.map((f) => f.id)
        hierarchy = hierarchy.concat(
          selectedOptions.filter((o) => availableIds.indexOf(o.id) === -1)
        )
      }
      return hierarchy.concat(this.archivedOptions)
    },
    suggestedItem() {
      if (this.suggestedValue && !this.value) {
        return this.options.find((o) => o.id === this.suggestedValue)
      }
      return null
    },
    currentStyle() {
      if (this.suggestedItem) {
        return getTagStyleFromColor('#89c53b', this.theme)
      }
      return {}
    },
  },
  created() {
    if (this.value) {
      const v = Array.isArray(this.value) ? this.value : [this.value]
      const options = this.options
      const flattenedOptions = flattenRecursive(options)
      const allAvailableUserIds = flattenedOptions.map((u) => u.id)
      const missingCategories = v.filter(
        (userId) => allAvailableUserIds.indexOf(userId) === -1 && !isNaN(userId)
      )
      if (missingCategories.length) {
        if (this.validateArchivedValue && !this.multiple) {
          this.$emit('change', this.unassignedValue)
        } else {
          this.fetchMissingCategories(missingCategories)
        }
      }
    }
  },
  methods: {
    fetchMissingCategories(missingCategories) {
      searchCategoriesApi(
        this.moduleName,
        {
          quals: [
            buildRelationalQualificationStructure(
              'id',
              'in',
              missingCategories,
              'long',
              'db'
            ),
          ],
        },
        undefined,
        undefined,
        { archived: true }
      ).then((data) => {
        this.archivedOptions = Object.freeze(
          (data.items || []).map((c) => ({
            ...c,
            disabled: c.archived || c.disabled,
          }))
        )
      })
    },
    handleSuggestedItemSelect() {
      this.$emit('change', this.suggestedValue)
      this.$emit('blur')
      this.$refs.treePickerRef && this.$refs.treePickerRef.handleHide()
    },
  },
}
</script>
<style lang="less" scoped>
.suggested-item-container {
  @apply rounded mx-2 p-2 flex flex-col cursor-pointer;

  color: var(--secondary-green);
  background: var(--success-outline-button-hover-bg);
}
</style>
