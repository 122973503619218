<template>
  <div>
    <RequesterEmailSelector
      ref="requesterEmailSelectorRef"
      :search-text="searchText"
      :options="requesters"
      :disabled="disabled"
      :auto-focus="autoFocus"
      v-bind="$attrs"
      @change="handleChange"
      @select="handleSelect"
      @search="searchText = $event"
      @blur="
        () => {
          $emit('blur')
          handleBlur(true)
        }
      "
    >
      <template v-slot:menu-item="{ item, selectItem }">
        <div
          class="dropdown-item flex items-center"
          href="javascript:;"
          @click.stop="selectItem(item)"
        >
          <FlotoAvatarName
            :avatar="item.avatar"
            :name="autoCompleteItemText(item)"
          />
        </div>
      </template>
    </RequesterEmailSelector>
    <div v-if="showHint" class="text-left" style="line-height: initial">
      <template v-if="selectedRequester">
        <div class="flex justify-start">
          <FlotoUserDrawer :user="selectedRequester">
            <template v-slot="{ open }">
              <span class="text-neutral text-xs">
                {{ $t('requester_name') }}:
              </span>
              <a @click="open">
                {{ autoCompleteItemText(selectedRequester) }}
                <tempalte v-if="selectedRequester.removed">
                  ({{ $t('archived') }})
                </tempalte>
              </a>
            </template>
          </FlotoUserDrawer>
        </div>
      </template>
      <template v-else-if="value && showHint && !selectedRequester && isBlured">
        <small class="text-neutral">
          {{ $t('requester_will_be_created') }}
        </small>
      </template>
    </div>
  </div>
</template>

<script>
import Throttle from 'lodash/throttle'
import api from '@api'
import { authComputed } from '@state/modules/auth'
import RequesterEmailSelector from './option-picker/requester-email-selector'
import { searchUserByEmailApi, getUserApi } from '@modules/users/users-api'
import { transformUserForVuex } from '@data/user'
import { SupportPortalConfigComputed } from '@state/modules/support-portal-config'

export default {
  name: 'FlotoRequesterEmail',
  components: { RequesterEmailSelector },
  model: {
    event: 'update',
  },
  props: {
    value: { type: [String, Number], default: undefined },
    size: { type: String, default: undefined },
    showHint: { type: Boolean, default: false },
    autoFocus: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    isEmail: { type: Boolean, default: false },
  },
  data() {
    return {
      requesters: [],
      loading: false,
      selectedRequester: undefined,
      isBlured: false,
      searchText: '',
    }
  },
  computed: {
    ...authComputed,
    ...SupportPortalConfigComputed,
    valueField() {
      if (this.isEmail) {
        return 'email'
      }
      return 'id'
    },
    searchField() {
      if (this.isEmail) {
        return 'email'
      }
      return 'name'
    },
  },
  watch: {
    searchText: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.handleSearch(newValue)
        }
      },
    },
  },
  created() {
    this.handleSearch = Throttle(this.handleSearch, 650)
    this.handleBlur()
    if (this.value && isNaN(this.value)) {
      this.searchText = this.value
    }
  },
  methods: {
    autoCompleteItemText(requester) {
      if (this.isEmail) {
        return requester.email
      }
      const suffix =
        requester.email || requester.userLogOnName
          ? `(${requester.email || requester.userLogOnName})`
          : ''

      return `${requester.name} ${suffix}`
    },
    handleChange(item) {
      const value =
        typeof item === 'object'
          ? item
          : { [this.valueField]: item, [this.searchField]: item }
      if (
        !value ||
        (this.selectedRequester &&
          `${value[this.searchField]}` !==
            `${this.selectedRequester[this.searchField]}`)
      ) {
        this.selectedRequester = undefined
        this.isBlured = false
      }
      this.$emit('update', value[this.valueField])
      this.searchText = value[this.searchField]
    },
    handleSelect(item) {
      if (item) {
        this.selectedRequester = item
        this.isBlured = false
        this.$emit('update', item[this.valueField])
        this.$emit('requesterSelected', {
          ...item,
          [this.valueField]: item[this.valueField],
          [this.searchField]: item[this.searchField],
        })
        this.searchText = item[this.searchField]
        this.$nextTick(() => {
          this.handleBlur()
        })
      }
    },
    handleSearch(value) {
      if (!this.loggedIn) {
        return
      }
      this.loading = true
      this.options = []
      searchUserByEmailApi(value, this.isEmail)
        .then((data) => {
          const r = data.filter((r) => `${r[this.searchField]}` === `${value}`)
          if (r.length === 1) {
            this.selectedRequester = r[0]
            this.$emit('requesterSelected', {
              ...r[0],
              [this.valueField]: r[0][this.valueField],
              [this.searchField]: r[0][this.searchField],
            })
            this.$refs.requesterEmailSelectorRef &&
              this.$refs.requesterEmailSelectorRef.hide()
          }
          this.requesters = data.map((i) => ({
            ...i,
            [this.valueField]: i[this.valueField],
            [this.searchField]: i[this.searchField],
          }))
        })
        .finally(() => (this.loading = false))
    },
    handleBlur(checkIsBlured) {
      if (this.isPortalLogin) {
        if (
          this.allowRequesterToCreateIncidentBehalfOfOtherRequester &&
          !this.selectedRequester
        ) {
          this.isBlured = true
          this.$emit('requesterSelected')
        }
        return
      }
      if (
        // email.validate(this.value) &&
        this.value &&
        !this.selectedRequester &&
        this.showHint
      ) {
        if (checkIsBlured) {
          this.isBlured = true
          this.$emit('requesterSelected')
        }
        if (isNaN(this.value)) {
          api
            .get(`/requester/byemail/${this.value}`, {
              notify: false,
            })
            .then((data) => {
              const user = transformUserForVuex(data)
              this.selectedRequester = {
                ...user,
                removed: data.removed || false,
                [this.valueField]: user[this.valueField],
                [this.searchField]: user[this.searchField],
              }
              this.$emit('set-default-value')
              this.searchText = user[this.searchField]
            })
            .catch(() => {
              this.$nextTick(() => {
                this.searchText =
                  this.value && isNaN(this.value)
                    ? this.value
                    : (this.selectedRequester || {})[this.searchField]
              })
            })
        } else {
          getUserApi('requester', this.value).then((data) => {
            if (data) {
              const user = transformUserForVuex(data)
              this.selectedRequester = {
                ...user,
                removed: data.removed || false,
                [this.valueField]: user[this.valueField],
                [this.searchField]: user[this.searchField],
              }
              this.searchText = user[this.searchField]
              this.$emit('requesterSelected', user)
              this.$emit('blur')
            }
          })
        }
      }
    },
  },
}
</script>
