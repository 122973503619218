<template>
  <div ref="googleSSOButtonRef">
    <MButton
      v-if="headerPage"
      id="sign-in-with-google-btn"
      :loading="processing"
      @click="login"
      >{{ $tc('sign_in_with_google') }}</MButton
    >
    <MButton
      v-else
      id="sign-in-with-google-btn"
      block
      class="p-0 rounded google-sso-btn font-semibold text-lg"
      :loading="processing"
      @click="login"
    >
      <div class="flex flex-1 items-center">
        <div class="flex justify-start google-img">
          <img src="/images/google-logo.png" height="43px" width="40px" />
        </div>
        <div class="flex flex-1 justify-center">{{
          $tc('sign_in_with_google')
        }}</div>
      </div>
    </MButton>
  </div>
</template>

<script>
import { googleSSOLoginApi } from '@modules/auth/auth-api'
const API_JS_URL = 'https://apis.google.com/js/api.js'
export default {
  name: 'GoogleSSOButton',
  props: {
    googleWebClientId: { type: String, required: true },
    headerPage: { type: Boolean, default: false },
  },
  data() {
    return {
      processing: false,
    }
  },
  methods: {
    loadGoogleJs() {
      return new Promise((resolve) => {
        const scriptElement = document.createElement('script')
        scriptElement.type = 'application/javascript'
        scriptElement.src = API_JS_URL
        scriptElement.onload = resolve
        document.body.appendChild(scriptElement)
        const button = document.createElement('button')
        // eslint-disable-next-line
        button.onclick = function(e) {
          window.gapi.auth2.getAuthInstance().signIn()
        }
        this.loginButton = button
      })
    },
    getGoogleUserIdToken() {
      const googleUser = window.gapi.auth2.getAuthInstance().currentUser.get()
      return googleUser.getAuthResponse(false).id_token
    },
    initializeClient(resolve) {
      return () => {
        window.gapi.client
          .init({
            clientId: this.googleWebClientId,
            scope: 'https://www.googleapis.com/auth/plus.login',
          })
          .then(() => {
            const authStatusChange = (isSignedIn) => {
              if (isSignedIn) {
                return resolve(this.getGoogleUserIdToken())
              }
            }
            window.gapi.auth2
              .getAuthInstance()
              .isSignedIn.listen(authStatusChange)
            window.gapi.auth2.getAuthInstance().signOut()
            this.loginButton.click()
          })
      }
    },
    initiateLoginFlow() {
      window.gapi.auth2.getAuthInstance().signIn()
    },
    login() {
      if (this.processing) {
        return
      }
      this.processing = true
      return new Promise((resolve, reject) => {
        if (!this.googleWebClientId) {
          return reject(new Error('Google Client Id not define'))
        }
        this.loadGoogleJs()
          .then(() =>
            window.gapi.load('client:auth2', this.initializeClient(resolve))
          )
          .catch(reject)
      })
        .then((googleToken) => {
          googleSSOLoginApi(googleToken)
            .then((data) => {
              this.processing = false
              this.$router.replace(
                this.$modules.getModuleRoute('auth', 'login', {
                  query: { mid: data.token, expires_in: data.expires_in },
                })
              )
            })
            .catch(() => {
              this.processing = false
            })
        })
        .catch(() => {
          this.processing = false
        })
    },
  },
}
</script>
<style lang="less" scoped>
.google-sso-btn {
  @apply rounded;

  height: 46px;
  padding: 0;
  font-weight: 500;
  background-color: #4285f4 !important;
}
.google-img {
  background-color: var(--white-regular);
  border-radius: 2px;
}
</style>
<style lang="less">
.google-sso-btn {
  &.ant-btn-loading {
    display: flex;
    padding-left: 0 !important;
    & .anticon:not(:last-child) {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}
</style>
