function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlotoFixedView',{attrs:{"gutter":0,"no-height":_vm.scrollable}},[_c('FlotoPaginatedList',{ref:"list",attrs:{"allow-keyboard-navigation":_vm.allowKeyboardNavigation,"immediate":_vm.immediate,"default-sort":_vm.defaultSort,"fetch-fn":_vm.fetchFn,"page-size":_vm.currentPageSize,"page":_vm.page,"paging":_vm.paging,"max-allowed-selection":_vm.maxAllowedSelection,"default-selected-item-ids":_vm.defaultSelectedItemIds},on:{"itemsReceived":_vm.handleNewItemsReceived,"selection-change":function($event){return _vm.$emit('selection-change', $event)},"select-item":function($event){return _vm.$emit('select-item', $event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var loading = ref.loading;
    var loadingMore = ref.loadingMore;
    var items = ref.items;
    var removeItem = ref.removeItem;
    var appendItem = ref.appendItem;
    var replaceItem = ref.replaceItem;
    var reset = ref.reset;
    var prependItem = ref.prependItem;
    var loadMoreItems = ref.loadMoreItems;
    var shouldRenderPagination = ref.shouldRenderPagination;
    var paginationMode = ref.paginationMode;
    var nextPage = ref.nextPage;
    var navigateToPage = ref.navigateToPage;
    var previousPage = ref.previousPage;
    var pageInfo = ref.pageInfo;
    var error = ref.error;
    var replaceAllItems = ref.replaceAllItems;
    var toggleSelectItem = ref.toggleSelectItem;
    var toggleSelectAll = ref.toggleSelectAll;
    var selectedItemIds = ref.selectedItemIds;
    var sortedColumn = ref.sortedColumn;
    var applySort = ref.applySort;
    var changePageSize = ref.changePageSize;
    var currentCursorPosition = ref.currentCursorPosition;
return [_c('div',{staticClass:"flex flex-col min-w-0 w-full min-h-0",staticStyle:{"flex":"1"}},[_c('FlotoCrud',{ref:"crud",attrs:{"update-fn":_vm.updateFn,"create-fn":_vm.createFn,"delete-fn":_vm.deleteFn,"default-item":_vm.defaultItem},on:{"form-hide":function($event){return _vm.$emit('form-hide')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var activateItem = ref.activateItem;
        var editingItem = ref.editingItem;
        var create = ref.create;
        var edit = ref.edit;
        var submitForm = ref.submitForm;
        var remove = ref.remove;
        var hideForm = ref.hideForm;
        var processing = ref.processing;
        var activeItem = ref.activeItem;
        var resetActiveItem = ref.resetActiveItem;
        var setProcessing = ref.setProcessing;
        var resetForm = ref.resetForm;
return [_c('div',{staticClass:"flex flex-col h-100"},[_c('div',{class:{ 'px-4': _vm.scrollable }},[_vm._t("add-controls",null,{"create":create,"loading":loading,"appendItem":appendItem,"refreshList":reset,"activateItem":activateItem,"activeItem":activeItem,"sortedColumn":sortedColumn,"applySort":applySort,"items":items,"resetActiveItem":resetActiveItem})],2),_vm._t("form",function(){return [_c('FlotoDrawerForm',{ref:"drawerFormRef",attrs:{"width":_vm.formWidth,"open":editingItem !== null},on:{"show":function($event){return setProcessing(false)},"submit":function($event){return _vm.handleFormSubmit(
                  submitForm,
                  editingItem,
                  hideForm,
                  editingItem.id
                    ? replaceItem
                    : _vm.prependNewItem
                    ? prependItem
                    : appendItem
                )},"cancel":hideForm},scopedSlots:_vm._u([(editingItem)?{key:"header",fn:function(){return [_vm._t("form-header",null,{"item":editingItem})]},proxy:true}:null,(editingItem)?{key:"actions",fn:function(ref){
                var hide = ref.hide;
                var submit = ref.submit;
                var rest = objectWithoutProperties( ref, ["hide", "submit"] );
                var formSlotData = rest;
return [_vm._t("form-actions",null,{"processing":processing,"item":editingItem,"cancel":hide,"submit":submit},formSlotData)]}}:null],null,true)},[(editingItem)?_vm._t("form-items",null,{"item":editingItem,"resetForm":_vm.getResetFormHandler(
                    resetForm,
                    items.find(function (i) { return i.id === editingItem.id; })
                  )}):_vm._e()],2)]},{"editingItem":editingItem,"submit":_vm.getSubmitEditingItemFormHandler(
                submitForm,
                editingItem,
                hideForm,
                replaceItem,
                prependItem,
                appendItem
              ),"cancel":hideForm,"defaultItem":_vm.defaultItem}),_vm._t("before-list"),_vm._t("bulk-action-section",function(){return [(selectedItemIds.length && _vm.$scopedSlots['bulk-actions'])?_c('div',{class:{ 'px-4': _vm.scrollable }},[_c('div',{staticClass:"p-2 flex items-center bulk-action-header"},[_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('a',{staticClass:"mr-2",on:{"click":function($event){return toggleSelectAll(false)}}},[_c('MIcon',{attrs:{"name":"minus-square","size":"lg"}})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('unselect'))+" "+_vm._s(_vm.$t('all'))+" ")]),_vm._t("bulk-actions",null,{"refreshList":reset,"toggleSelectAll":toggleSelectAll,"selectedItemIds":selectedItemIds,"items":items})],2)]):_vm._e()]},{"refreshList":reset,"toggleSelectAll":toggleSelectAll,"selectedItemIds":selectedItemIds,"items":items}),_c('div',{staticClass:"flex flex-1 flex-col min-h-0"},[_c('FlotoContentLoader',{attrs:{"loading":loading},scopedSlots:_vm._u([{key:"waiting",fn:function(){return [(loading)?_vm._t("loading",function(){return [_c('MRow',{staticClass:"w-full",class:{ 'px-4': _vm.scrollable }},[_c('MCol',{attrs:{"xxl":12}},[_c('MPlaceholder',{attrs:{"width":"100%","height":200,"primary-color":"var(--loader-primary-color)","secondary-color":"var(--loader-secondary-color)"}})],1)],1)]}):_vm._e()]},proxy:true}],null,true)},[_c('MRow',{staticClass:"flex-1 flex-no-wrap overflow-hidden min-h-0",attrs:{"gutter":0}},[_c('MCol',{staticClass:"min-w-0"},[_c(_vm.scrollable ? 'FlotoScrollView' : 'div',{tag:"component",attrs:{"show-duration":_vm.$attrs['scroll-show-duration']}},[_c('div',{staticClass:"flex flex-1 flex-col",class:{ 'px-4': _vm.scrollable }},[(!loading && !error)?_vm._t("list-items",function(){return [(_vm.asTable)?_c('ResizableTable',_vm._b({attrs:{"use-scroll":!_vm.scrollable,"disabled":(selectedItemIds.length &&
                              _vm.$scopedSlots['bulk-actions']) ||
                            _vm.hideTableHeaders,"sticky-headers":""}},'ResizableTable',items.length === 0 ? { minHeight: 0 } : {},false),[_c('table',{key:"tabular-list",staticClass:"item-list-table w-full"},[(
                                !(
                                  selectedItemIds.length &&
                                  _vm.$scopedSlots['bulk-actions']
                                ) && _vm.hideTableHeaders !== true
                              )?_c('thead',[_c('tr',[(_vm.selectable)?_c('td',{staticClass:"checkbox sticky"},[(
                                      _vm.maxAllowedSelection === 0 &&
                                      items.length > 0
                                    )?_c('MCheckbox',{attrs:{"checked":selectedItemIds.length ===
                                        items.length && items.length > 0},on:{"change":toggleSelectAll}}):_vm._e()],1):_vm._e(),_vm._l((_vm.columns),function(column){
                                        var _obj;
return _c('td',{key:column.key,staticClass:"text-ellipsis sticky",class:( _obj = {}, _obj[column.class || ''] = true, _obj['non-resizable'] = 
                                      column.resizable === false, _obj ),style:(_vm.columnStyle(column)),attrs:{"data-initial-width":column.width,"align":column.align ? column.align : undefined}},[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"min-w-0 flex-1 text-ellipsis"},[_vm._v(" "+_vm._s(column.name)+" ")]),(column.sortable)?_c('MIcon',{staticClass:"cursor-pointer",attrs:{"name":sortedColumn
                                          ? sortedColumn.replace('-', '') ===
                                            column.key
                                            ? /^-/.test(sortedColumn)
                                              ? 'sort-up'
                                              : 'sort-down'
                                            : 'sort'
                                          : 'sort'},on:{"click":function($event){return applySort(column.key)}}}):_vm._e()],1)])})],2)]):_vm._e(),(items.length)?_c('SortableList',{attrs:{"value":items,"tag":"tbody","disabled":!_vm.sortable,"handle":".drag-handle"},on:{"drag-start":function($event){return _vm.$emit('drag-start')},"drag-end":function($event){return _vm.$emit('drag-end')},"update":function($event){return _vm.handleItemsOrderChange(
                                  $event,
                                  items,
                                  replaceAllItems
                                )}}},[_vm._l((items),function(item,index){return [_c('tr',{key:item.id,class:{
                                    'active-cursor':
                                      _vm.allowKeyboardNavigation &&
                                      index === currentCursorPosition,
                                  },attrs:{"id":item.name}},[(_vm.selectable)?_c('td',{staticClass:"checkbox"},[_c('MCheckbox',{attrs:{"checked":selectedItemIds.indexOf(item.id) >=
                                          0 ||
                                        _vm.defaultSelected.indexOf(item.id) >= 0,"disabled":(_vm.selectedDisabled &&
                                          _vm.defaultSelected.indexOf(item.id) >=
                                            0) ||
                                        item.disableSelection},on:{"change":function($event){return toggleSelectItem(item)}}})],1):_vm._e(),_vm._l((_vm.columns),function(column){return _vm._t(column.key,function(){return [_c('td',{key:column.key,staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(item[column.key])+" ")])]},{"activateItem":activateItem,"index":index,"edit":function () { return edit(item); },"create":function (payload) { return create(payload); },"refreshList":reset,"activeItem":activeItem,"isFirstItem":index === 0,"isLastItem":index === items.length - 1,"update":function (payload) { return submitForm(payload).then(function (response) { return replaceItem(response); }
                                        ); },"remove":function () { return remove(item).then(function () { return removeItem(item); }
                                        ); },"item":item,"items":items,"resetActiveItem":resetActiveItem,"currentCursorPosition":currentCursorPosition,"toggleSelectAll":toggleSelectAll,"processing":processing})})],2)]})],2):_vm._e()],1)]):_vm._e(),(items.length && !_vm.asTable && !loading)?_c('div',{staticClass:"flex-1"},[_vm._l((items),function(item,index){return _vm._t("item",null,{"index":index,"isFirstItem":index === 0,"isLastItem":index === items.length - 1,"activateItem":activateItem,"edit":function () { return edit(item); },"create":function (payload) { return create(payload); },"activeItem":activeItem,"resetActiveItem":resetActiveItem,"currentCursorPosition":currentCursorPosition,"replaceAllItems":replaceAllItems,"update":function (payload) { return submitForm(payload).then(function (response) {
                                  replaceItem(response)
                                  if (response.id === (activeItem || {}).id) {
                                    activateItem(response)
                                  }
                                }); },"remove":function () { return remove(item).then(function () { return removeItem(item); }); },"item":item,"sortedColumn":sortedColumn,"applySort":applySort,"toggleSelectItem":function () { return toggleSelectItem(item); },"isSelected":selectedItemIds.indexOf(item.id) >= 0})})],2):_vm._e()]},null,{
                          submit: _vm.getSubmitEditingItemFormHandler(
                            submitForm,
                            editingItem,
                            hideForm,
                            replaceItem,
                            prependItem,
                            appendItem
                          ),
                          items: items,
                          edit: edit,
                          create: create,
                          activeItem: activeItem,
                          activateItem: activateItem,
                          sortedColumn: sortedColumn,
                          applySort: applySort,
                          update: function (payload, item) {
                            return submitForm(payload, item).then(
                              function (response) {
                                replaceItem(response, item)
                                if (response.id === (activeItem || {}).id) {
                                  activateItem(response, item)
                                }
                              }
                            )
                          },
                          appendItem: appendItem,
                          prependItem: prependItem,
                          replaceItem: replaceItem,
                          removeItem: removeItem,
                          remove: function (x) { return remove(x).then(function () { return removeItem(x); }); },
                          reset: reset,
                          replaceAllItems: replaceAllItems,
                          resetActiveItem: resetActiveItem,
                          toggleSelectItem: toggleSelectItem,
                          toggleSelectAll: toggleSelectAll,
                          selectedItemIds: selectedItemIds,
                          navigateToPage: navigateToPage,
                          changePageSize: changePageSize,
                          pageInfo: pageInfo,
                          currentCursorPosition: currentCursorPosition,
                        }):_vm._e(),(
                          !items.length && !error && _vm.showNoData && !loading
                        )?_vm._t("no-data",function(){return [_c('FlotoNoData',{attrs:{"size":_vm.messageSize}})]}):_vm._e(),(error)?_vm._t("error",function(){return [_c('FlotoLoadError',{attrs:{"size":_vm.messageSize,"authorization-error":error.response && error.response.status === 403}},[_c('MButton',{on:{"click":reset}},[_vm._v(" "+_vm._s(_vm.$t('reload'))+" ")])],1)]},{"refresh":reset,"error":error}):_vm._e()],2)])],1)],1),(shouldRenderPagination && !loading)?_vm._t("pagination",function(){return [_c('MRow',{staticClass:"my-2",class:{ 'px-4': _vm.scrollable },attrs:{"gutter":0}},[_c('MCol',{class:{
                      'text-center': paginationMode === 'loadmore',
                      'text-right': paginationMode === 'paging',
                    }},[(paginationMode === 'loadmore' && !loadingMore)?_c('MButton',{attrs:{"loading":loading},on:{"click":loadMoreItems}},[_vm._v(" "+_vm._s(_vm.$t('load_more'))+" ")]):_vm._e(),(paginationMode !== 'loadmore')?_c('MPagination',{attrs:{"hide-on-single-page":false,"size":"small","page-size":pageInfo.pageSize,"show-size-changer":"","total":pageInfo.total,"show-total":function (total, range) { return _vm.$t('pagination_info', {
                            from: range[0],
                            to: range[1],
                            total: total,
                            item: _vm.resourceName || _vm.$tc('record', 2),
                          }); }},on:{"change":navigateToPage,"update:page-size":changePageSize},scopedSlots:_vm._u([{key:"default",fn:function(slotData){return [_c('span',[_vm._v(_vm._s(slotData.value)+" / "+_vm._s(_vm.$tc('page')))])]}}],null,true),model:{value:(pageInfo.current),callback:function ($$v) {_vm.$set(pageInfo, "current", $$v)},expression:"pageInfo.current"}}):_vm._e()],1)],1)]},{"paginationMode":paginationMode,"loadMoreItems":loadMoreItems,"nextPage":nextPage,"previousPage":previousPage,"navigateToPage":navigateToPage,"changePageSize":changePageSize,"pageInfo":pageInfo}):_vm._e(),_vm._t("after-list",null,{"selectedItemIds":selectedItemIds,"refreshList":reset}),(loadingMore && paginationMode === 'loadmore')?_c('MRow',{staticClass:"w-full mt-4",class:{ 'px-4': _vm.scrollable },attrs:{"gutter":0}},[_c('MCol',{attrs:{"xxl":12}},[_vm._t("loading",function(){return [_c('MPlaceholder',{attrs:{"width":"100%","height":200,"primary-color":"var(--loader-primary-color)","secondary-color":"var(--loader-secondary-color)"}})]})],2)],1):_vm._e()],2)],1),_vm._t("default",null,null,{
              activeItem: activeItem,
              activateItem: activateItem,
              resetActiveItem: resetActiveItem,
              editingItem: editingItem,
              appendItem: appendItem,
              prependItem: prependItem,
              replaceItem: replaceItem,
              removeItem: removeItem,
              toggleSelectItem: function () { return toggleSelectItem(_vm.item); },
              selectedItemIds: selectedItemIds,
              remove: function (item) { return remove(item).then(function () { return removeItem(item); }); },
              reset: reset,
              items: items,
              update: function (payload) { return submitForm(payload).then(function (response) {
                  replaceItem(response)
                  if (response.id === (activeItem || {}).id) {
                    activateItem(response)
                  }
                }); },
              loading: loading,
            })],2)]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }