<script>
import { getCompaniesApi } from '@modules/organization/company-api'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'
import UniqBy from 'lodash/uniqBy'

export default {
  name: 'CompanyPicker',
  extends: _baseDropdownPicker,
  props: {
    value: { type: [Number, Array], default: undefined },
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
    // eslint-disable-next-line
    immediateSearch: { type: Boolean, default: true },
    validateArchivedValue: { type: Boolean, default: false },
  },
  data() {
    this.unassignedValue = 0
    return {
      archivedOptions: [],
      loadedCompanies: [],
      selectedEventName: 'companySelected',
    }
  },
  computed: {
    options() {
      // @TODO when value is given and total is more than 1000 then we need to use search api to get items
      const options = this.loadedCompanies
      const filteredOptions = options.filter((c) => !c.archived)
      const value = Array.isArray(this.value) ? this.value : [this.value]
      const selectedOptions = value.length
        ? options
            .filter((o) => value.indexOf(o.id) >= 0)
            .map((o) => ({ ...o, disabled: true }))
        : []
      let avialableOptions = filteredOptions
      if (selectedOptions.length) {
        const availableIds = filteredOptions.map((f) => f.id)
        avialableOptions = avialableOptions.concat(
          selectedOptions.filter((o) => availableIds.indexOf(o.id) === -1)
        )
      }
      return UniqBy(avialableOptions.concat(this.archivedOptions), 'key')
    },
  },
  watch: {
    options: {
      immediate: true,
      handler(newValue) {
        if (this.value) {
          const v = Array.isArray(this.value) ? this.value : [this.value]
          const options = newValue
          const avialableOptions = this.$attrs['additional-options']
            ? (this.$attrs['additional-options'] || []).concat(options)
            : options
          const allAvailableServiceIds = avialableOptions.map((u) => u.key)
          const missingItems = v.filter(
            (id) => allAvailableServiceIds.indexOf(id) === -1
          )
          if (missingItems.length) {
            if (
              this.validateArchivedValue &&
              !this.multiple &&
              !this.immediateSearch
            ) {
              this.$emit('change', this.unassignedValue)
            } else {
              this.fetchMissingItems(missingItems)
            }
          }
        }
      },
    },
  },
  created() {
    getCompaniesApi().then(({ items }) => {
      return (this.loadedCompanies = items.map((t) => ({
        key: t.id,
        text: t.displayName,
      })))
    })
  },
  methods: {
    fetchMissingItems(missingItems) {
      getCompaniesApi(
        {
          ids: missingItems,
        },
        undefined,
        undefined,
        { archived: true }
      ).then((data) => {
        this.archivedOptions = Object.freeze(
          (data.items || []).map((c) => ({
            key: c.id,
            text: c.displayName,
            disabled: true,
            archived: true,
          }))
        )
      })
    },
  },
}
</script>
