<template>
  <FlotoDropdownPicker
    :multiple="multiple"
    :use-mselect="false"
    :options="options"
    :as-input="asInputComputed"
    :value="value"
    :keep-unassigned="keepUnassigned"
    :disabled="disabled"
    searchable
    :placeholder="placeholder"
    :overlay-style="{ zIndex: 1051 }"
    v-bind="$attrs"
    @change="handleChange"
  >
    <template v-if="!multiple" v-slot:trigger="triggerSlotData">
      <slot name="trigger" v-bind="triggerSlotData">
        <template v-if="!asInputComputed">
          <span
            v-if="triggerSlotData.currentItem"
            class="flex items-center technician-picker-trigger"
            :class="{ 'cursor-pointer': !disabled }"
            @click="triggerSlotData.toggle"
          >
            <slot
              name="selected-technician"
              :technician="triggerSlotData.currentItem"
              :size="size"
            >
              <FlotoUserAvatar
                v-if="showAvatar"
                :size="size"
                :avatar="(triggerSlotData.currentItem || {}).avatar"
                class="mr-2"
                :style="{
                  minWidth: `${
                    size === 'small' ? 24 : size === 'medium' ? 32 : 40
                  }px`,
                }"
              />
              <div class="text-ellipsis mx-1">
                <div class="text-neutral-light">
                  <slot name="label"> </slot>
                </div>
                {{ triggerSlotData.currentItem.name }}
              </div>
            </slot>
          </span>
        </template>
        <div
          v-else
          @mouseenter="handleMouseEnter"
          @mouseleave="isActiveHover = false"
          @click="triggerSlotData.toggle"
        >
          <MInput
            read-only
            :value="
              triggerSlotData.currentItem
                ? triggerSlotData.currentItem.name
                : undefined
            "
            :placeholder="placeholder || `${$t('select')} ${$tc('technician')}`"
            class="dropdown-trigger-input"
            :disabled="disabled"
            :class="[inputClasses]"
            @focus="triggerSlotData.toggle"
          >
            <FlotoUserAvatar
              v-if="triggerSlotData.currentItem"
              slot="prefix"
              :size="20"
              :avatar="(triggerSlotData.currentItem || {}).avatar"
              :style="isRtl ? { left: '5px' } : { right: '5px' }"
            />
            <MIcon
              v-if="isActiveHover && allowClear && !disabled"
              slot="suffix"
              type="fas"
              name="times-circle"
              size="sm"
              class="cursor-pointer"
              @click.stop="handleChange(undefined)"
            />
            <MIcon
              v-else
              slot="suffix"
              type="fal"
              name="chevron-down"
              size="sm"
              class="cursor-pointer text-neutral-light"
            />
          </MInput>
        </div>
      </slot>
    </template>
    <template v-slot:menu-item="{ item, selectItem }">
      <a class="flex" style="max-width: 350px" @click.stop="selectItem(item)">
        <FlotoAvatarName
          :avatar="item.avatar"
          :name="item.name"
          :size="size"
          :use-icon="[0, 'any'].indexOf(item.id) >= 0"
        >
          <template
            v-if="!multiple && !isPortalLogin"
            v-slot:availability-status
          >
            <TechnicianAvailabilityStatus
              :key="item.id"
              :user-id="item.id"
              :logged-in-technicians="loggedInTechnicians"
            />
          </template>
          <div v-if="!multiple" class="flex">
            <div class="load-indicator" :class="item.loadIndicator">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </FlotoAvatarName>
      </a>
    </template>
  </FlotoDropdownPicker>
</template>

<script>
import { TechnicianComputed } from '@state/modules/technician'
import {
  getUsersApi,
  getUsersPublicApi,
  getActiveTechniciansApi,
} from '@modules/users/users-api'
import { buildRelationalQualificationStructure } from '../../data/qualification'
import TechnicianAvailabilityStatus from '@components/technician-availability-status'
import { authComputed } from '@state/modules/auth'
import { isLoggedIn } from '@utils/auth'
export default {
  name: 'FlotoTechnicianPicker',
  components: { TechnicianAvailabilityStatus },
  inheritAttrs: false,
  model: {
    event: 'change',
  },
  props: {
    activeTechnicians: { type: Object, default: undefined },
    hideTenant: { type: Boolean, default: false },
    changeTechnicianOnGroupChange: { type: Boolean, default: false },
    groupId: { type: Number, default: undefined },
    placement: { type: String, default: 'bottomLeft' },
    multiple: { type: Boolean, default: false },
    mandatorySelection: { type: Boolean, default: false },
    keepUnassigned: { type: Boolean, default: false },
    value: { type: [Number, Array, String], default: undefined },
    disabled: { type: Boolean, default: false },
    asInput: { type: Boolean, default: false },
    inputClasses: { type: [String, Object, Array], default: undefined },
    size: {
      type: [String, Number],
      default: 'small',
    },
    placeholder: { type: String, default: undefined },
    excludeIds: {
      type: Array,
      default() {
        return []
      },
    },
    includeIds: {
      type: Array,
      default: undefined,
    },
    validateArchivedValue: { type: Boolean, default: false },
    excludeBlockedTechnicians: { type: Boolean, default: false },
    // eslint-disable-next-line
    showAvatar: { type: Boolean, default: true },
    // eslint-disable-next-line
    allowClear: { type: Boolean, default: true },
  },
  data() {
    return {
      isActiveHover: false,
      archivedOptions: [],
      loggedInTechnicians: {},
    }
  },
  computed: {
    ...TechnicianComputed,
    ...authComputed,
    asInputComputed() {
      if (this.multiple) {
        return true
      }
      return this.asInput
    },
    allOptions() {
      let currentList = this.technicianOptions
      if (this.$attrs['additional-options']) {
        currentList = (this.$attrs['additional-options'] || []).concat(
          currentList
        )
      }
      if (this.excludeBlockedTechnicians) {
        currentList = currentList.filter(
          (c) =>
            (c.blocked !== true && c.isSuperAdmin === false) ||
            (c.isSuperAdmin !== true && c.blocked === false)
        )
        return currentList
      }
      return currentList.concat(this.archivedOptions)
    },
    options() {
      let currentList = this.allOptions
      if (
        this.mandatorySelection ||
        (this.multiple && this.keepUnassigned === false)
      ) {
        currentList = currentList.map((i) => ({
          ...i,
          ...(i.id === 0 ? { disabled: true } : {}),
        }))
      }
      if (this.groupId) {
        currentList = currentList.filter(
          (u) => (u.groups || []).indexOf(this.groupId) >= 0 || u.id === 0
        )
      }
      if (this.hideTenant) {
        currentList = currentList.filter((u) => u.isTenant !== true)
      }
      if (this.excludeIds.length) {
        currentList = currentList.filter(
          (u) => this.excludeIds.indexOf(u.id) === -1
        )
      }
      if (this.includeIds) {
        const includeIds = this.includeIds
        currentList = currentList.filter((u) => includeIds.indexOf(u.id) >= 0)
      }
      return currentList
    },
    currentUser() {
      if (this.value) {
        return (this.allOptions || []).find(
          (p) => p.key === this.value || p.key === parseInt(this.value)
        )
      }
      return undefined
    },
  },
  watch: {
    groupId(newValue) {
      if (!this.changeTechnicianOnGroupChange) {
        return
      }
      if (this.value === 0) {
        return
      }
      if (
        this.currentUser &&
        (this.currentUser.groups || []).indexOf(this.groupId) === -1
      ) {
        this.handleChange(0)
      }
    },
    activeTechnicians: {
      immediate: true,
      handler(newValue) {
        if (newValue && !this.isPortalLogin && !this.multiple) {
          this.loggedInTechnicians = newValue
        }
      },
    },
  },
  created() {
    if (!this.multiple && !this.isPortalLogin && !this.disabled) {
      if (!this.activeTechnicians) {
        this.getActiveTechnicians()
      } else {
        this.loggedInTechnicians = this.activeTechnicians
      }
    }
    if (this.value) {
      const v = Array.isArray(this.value) ? this.value : [this.value]
      const allAvailableUserIds = this.allOptions.map((u) => u.key)
      const missingUsers = v.filter(
        (userId) => allAvailableUserIds.indexOf(userId) === -1
      )
      if (missingUsers.length) {
        this.fetchMissingTechnicians(missingUsers)
      }
    }
    // set dynamic watch on value for handle archived value validation
    if (this.validateArchivedValue && !this.multiple) {
      this.$watch('value', {
        immediate: true,
        handler(newValue) {
          if (
            newValue &&
            (this.allOptions.filter((o) => o.key === newValue).length === 0 ||
              this.allOptions.find((o) => o.key === newValue).archived)
          ) {
            this.$nextTick(() => {
              this.handleChange(0)
            })
          }
        },
      })
    }
  },
  methods: {
    fetchMissingTechnicians(technicianIds) {
      const search = {
        quals: [
          buildRelationalQualificationStructure(
            'id',
            'in',
            technicianIds,
            'long',
            'db'
          ),
        ],
      }
      let getFunction
      if (isLoggedIn()) {
        getFunction = getUsersApi
      } else {
        getFunction = getUsersPublicApi
      }
      return getFunction('requester', {}, search, undefined, undefined, {
        archived: true,
      }).then((data) => {
        this.archivedOptions = Object.freeze(
          (data.items || []).map((u) => ({
            key: u.id,
            id: u.id,
            name: `${u.name} ${u.archived ? `(${this.$t('archived')})` : ``}`,
            email: u.email,
            avatar: u.avatar,
            loadIndicator: u.loadIndicator || 'low',
            archived: u.archived, //! this.multiple,
            disabled: u.archived,
          }))
        )
      })
    },
    handleChange(option) {
      if (this.multiple) {
        this.$emit(
          'selected',
          option.map((id) =>
            this.options.find(
              (o) => o.key === id || o.id === id || o.value === id
            )
          )
        )
        this.$emit('change', option)
        this.$emit('blur')
      } else {
        this.$emit(
          'selected',
          this.options.find(
            (o) => o.key === option || o.id === option || o.value === option
          )
        )
        this.$emit('change', option || 0)
        this.$emit('blur')
      }
    },
    getActiveTechnicians() {
      getActiveTechniciansApi().then((data) => {
        this.loggedInTechnicians = data
      })
    },
    handleMouseEnter() {
      if (this.value) {
        this.isActiveHover = true
      }
    },
  },
}
</script>

<style lang="less">
.load-indicator {
  width: 10px;

  @apply flex flex-col;

  div {
    margin-bottom: 1px;

    @apply flex-1;

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      margin-bottom: 0;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  &.low {
    div {
      border: 1px solid @secondary-green;

      &:last-child {
        background: @secondary-green;
      }
    }
  }

  &.medium {
    // stylelint-disable-next-line
    div {
      background: @secondary-yellow;
      border: 1px solid @secondary-yellow;

      &:first-child {
        background: transparent;
      }
    }
  }

  &.high {
    // stylelint-disable-next-line
    div {
      background: @secondary-red;
      border: 1px solid @secondary-red;
    }
  }
}
</style>
